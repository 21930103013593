
* {
  --bs-body-color: #727272;
  font-family: "Open Sans", serif;
}

.content-container {
  font-family: "Open Sans",serif;
}

#footer {
  background-color: #f8f8f9;
  background: linear-gradient(#f8f8f9,#fff);
  margin-top: 58px;
  box-shadow: inset 0 20px 16px -20px rgb(19 19 19 / 8%);
  text-align: center;
  padding: 50px 0 55px;
}

h1, h2, h3, h4, h5 {
  color: #000;
}

h1 {
  font-weight: 400;
  font-size: 34px;
}

h3 {
  color: #000;
  font-size: 23px;
  font-weight: 300;
  font-style: normal;
}

.imprint {
  padding-top: 3rem!important;
}

header {
  box-shadow: 0 7px 22px rgb(19 19 19 / 8%);
}

.navbar {
  padding: 5px 8px;
  --bs-light-rgb: #fff;
}

.navbar-brand {
  padding: 7px 15px;
}

.footer-item {
  display: inline-block;
}

.footer-item-border {
  border-right: 1px solid #999898;
}

.footer-navbar-nav {
  padding: 0;
}

.footer-link {
  font-size: 75%;
  color: #727272!important;
  -webkit-transition: color .5s ease;
  transition: color .5s ease;
  padding-right: 1rem!important;
  padding-left: 1rem!important;
  padding: 0;
}

#footer a {
  color: #727272;
  text-decoration: none;
}

a {
  color: #177ac9 !important;
  text-decoration: none !important;
  background-color: transparent !important;
  -webkit-text-decoration-skip: objects;
}

.footer-subline {
  color: #a1a1a1!important;
  font-size: 75%;
}